import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class IssuedDocumentPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.issuedDocumentRead,
        );

        this.readStaff = permissionChecker.match(
            Permissions.values.issuedDocumentStaffRead,
        );

        this.view = permissionChecker.match(
            Permissions.values.issuedDocumentView,
        );
    }
}
