<template>
    <div>
        <st-filters-pagination
            stateModule="issuedDocument/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="header"
        >
            <template #filters-toolbar>
                <slot name="toolbar"></slot>
            </template>
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { IssuedDocumentModel } from '@/modules/issued-document/issued-document-model';
    import { IssuedDocumentPermissions } from '@/modules/issued-document/issued-document-permissions';

    const { fields } = IssuedDocumentModel;

    const filterSchema = new FilterSchema([
        fields.issuer_user_id,
        fields.document_name,
        fields.issued_year,
        fields.issued_date,
        fields.beneficiary_full_names,
        fields.document_series,
    ]);
    export default {
        name: 'IssuedDocumentListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'issuedDocument/list/total',
                currentUser: 'auth/currentUser',
            }),
            hasPermissionToStaffIssuedDocument() {
                return new IssuedDocumentPermissions(this.currentUser).readStaff;
            },
            header() {
                return this.hasPermissionToStaffIssuedDocument ?
                    this.$t('ISSUED_DOCUMENT.LIST.TITLE_STAFF') : this.$t('ISSUED_DOCUMENT.LIST.TITLE');
            },
        },
        methods: {
            ...mapActions({
                doFetch: 'issuedDocument/list/doFetch',
                doSetFilter: 'issuedDocument/list/doSetFilter',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },
        created() {
            const number = this.$route.query.number;
            if (number) {
                this.doSetFilter({ key: 'document_series', value: number });
            }
        }

    };
</script>
