import * as yup from 'yup';

export class FilterSchema {
    fields;

    constructor(fields) {
        this.fields = fields;
    }

    initialValues(record = {}, queryParams = {}) {
        queryParams = queryParams || {};
        record = record || {};

        const hasFilterFromQuery = Object.values(queryParams).some(
            (filterValue) => !!filterValue,
        );

        if (hasFilterFromQuery) {
            record = queryParams;
        }

        const casted = {};

        for (const field of this.fields) {
            casted[field.name] = field.forFilterInitialValue(
                record[field.name],
            );
        }

        return casted;
    }

    rules() {
        const rules = {};

        this.fields.forEach((field) => {
            rules[field.name] = {};
            rules[field.name].validators = {
                ...field.forFormRules()
            };
        });

        return rules;
    }

    castSchema() {
        const shape = {};

        this.fields.forEach((field) => {
            shape[field.name] = field.forFilterCast();
        });

        return yup.object().shape(shape);
    }

    cast(values) {
        return this.castSchema().cast(values);
    }
}
